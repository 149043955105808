























import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import bwipjs from 'bwip-js';
import { PropType } from 'vue';
import SvgImage from '~/components/General/SvgImage.vue';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';
import { usePaymentProvider } from '~/composables';
import { ItauBoletoResponse } from '~/server/payments/types/BoletoTypes';

export default defineComponent({
  name: 'SlipPaymentComponent',
  components: {
    JetUiButton,
    SvgImage,
  },
  props: {
    slipData: {
      type: Object as PropType<ItauBoletoResponse>,
      required: true,
    },
    price: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const barcodeSvg = ref(null);
    const { getSlipPdf } = usePaymentProvider();
    onMounted(async () => {

      const generateBarcode = () => {
        try {
          const svg = bwipjs.toSVG({
            bcid: 'interleaved2of5',
            text: props.slipData.dado_boleto.dados_individuais_boleto[0].codigo_barras,
            sizelimit: 2,
            textxalign: 'center',
          });
          return svg;
        } catch (error) {
          console.error('Erro ao gerar código de barras:', error);
          return null;
        }
      };

      barcodeSvg.value = generateBarcode();
    });

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text);
    };

    const downloadPdf = async () => {
      let boleto = props.slipData;
      boleto.dado_boleto.dados_individuais_boleto[0].valor_titulo = props.price;


      const response = await getSlipPdf(props.slipData);
      if (!response.FileArq) {
        alert('PDF não encontrado.');
        return;
      }

      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${response.FileArq}`;
      link.download = 'boleto.pdf';
      link.click();
    };

    return {
      barcodeSvg,
      copyToClipboard,
      downloadPdf,
    };
  },
});
