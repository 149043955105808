




















import { defineComponent } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';

interface Brand {
  title: string;
  imagePath: string;
}

export default defineComponent({
  components: {
    SfCarousel,
  },
  props: {
    settings: {
      default: () => ({
        type: 'carousel',
        rewind: false,
        slidePerPage: false,
        hoverPause: true,
        perView: 5,
        autoplay: 3000,
        gap: 35,

        breakpoints: {
          1700: {
            perView: 4,
            gap: 15,

          },
          1024: {
            perView: 3,

          },
          768: {
            perView: 2,
          },
        },
      }),
    },
  },
  data() {
    return {
      windowWidth: 1920,
      randomBrands: [] as Brand[],
    };
  },
  computed: {
    isMobile(): boolean {
      return this.windowWidth < 1024;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize as EventListener);
    this.loadRandomBrands();
  },
  methods: {
    onResize(): void {
      this.windowWidth = window.innerWidth;
    },
    loadRandomBrands(): void {
      const requireSvg = (require as any).context('~/static/brands/', false, /\.svg$/);
      const svgs = requireSvg.keys().map((fileName: string) => ({
        title: fileName.replace('./', '').replace('.svg', ''),
        imagePath: requireSvg(fileName),
      }));

      this.randomBrands = this.shuffleArray(svgs).slice(0, 5);
    },
    shuffleArray(array: Brand[]): Brand[] {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
  },
});
