







































































































import {
computed,
defineComponent,
onMounted,
ref,
useFetch,
} from '@nuxtjs/composition-api';

import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import JetImage from '~/components/JetUi/atoms/JetImage/JetImage.vue';
import Bigbrands from '~/components/JetUi/molecules/bigbrands/bigbrands.vue';
import BannerCarousel from '~/components/JetUi/organisms/Banner/BannerCarousel.vue';
import BenefitsDisplay from '~/components/JetUi/organisms/Benefits/BenefitsDisplay.vue';
import ProductShowcase from '~/components/JetUi/organisms/Product/ProductShowcase.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useContent } from '~/composables';
import { CmsPage } from '~/modules/GraphQL/types';
import BankSlipModal from '~/modules/checkout/pages/Checkout/JetCheckout/JetCheckoutModals/BankSlipModal.vue';
import BlogCarrousel from '~/server/blog/BlogCarrousel.vue';
declare const require: {
  context: (path: string, useSubdirectories?: boolean, regExp?: RegExp) => {
    keys: () => string[];
    (key: string): string;
  };
};
export default defineComponent({
  name: 'HomePage',
  components: {
    Bigbrands,
    SkeletonLoader,
    LoadWhenVisible,
    ProductShowcase,
    BannerCarousel,
    BenefitsDisplay,
    JetImage,
    BankSlipModal,
    BlogCarrousel
  },
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const loaded = ref(false);
    const banners = ref([]);
    const homeBanners = ref([]);
    const settings = {
      type: 'carousel',
      perView: 1,
      autoplay: 5000,
      hoverpause: true,
      gap: 0,
    };

    const loadCarouselBanners = async () => {
      const context = require.context(
        '@/static/banners/carousel/desktop',
        false,
        /\.(png|gif)$/
      );

      const bannersMap = {};
      const links = await getBannerLinks();

      context.keys().forEach((fileName) => {
        const fileNameCleaned = fileName.replace('./', '');
        const filePath = `/banners/carousel/desktop/${fileNameCleaned}`;
        const [, id, expire] = fileName
          .replace('./', '')
          .replace('.png', '')
          .split('_');

        const [day, month, year] = expire.split('-').map(Number);
        const expireDate = new Date(year, month - 1, day);
        const today = new Date();

        if (expireDate < today) {
          changeBanner(fileName, 'desktop');
          changeBanner(fileName, 'mobile');
          return;
        }

        if (
          !bannersMap[id] ||
          expireDate < bannersMap[id].expireDate
        ) {
          bannersMap[id] = {
            id,
            expireDate,
            image: filePath,
            imageMobile: filePath.replace('desktop', 'mobile'),
            link: links[fileNameCleaned],
          };
        }
      });

      banners.value = Object.values(bannersMap);
    };

    const getBannerLinks = async () => {
      try {
        const response = await fetch("/banners.json");
        return response.json();
      } catch (error) {
        console.error("Error fetching banner links:", error);
        return {}
      }
    }

    const changeBanner = (fileName: string, type: string) => {
      fetch('/image/move', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileName: fileName.replace('./', ''),
          fromFolder: `banners/carousel/${type}`,
          toFolder: `banners/carousel/inactive/${type}`,
        }),
      });
    }

    const loadHomeBanners = () => {
      const context = require.context(
        '@/static/banners/home/desktop',
        false,
        /\.png$/
      );

      homeBanners.value = context.keys().map((fileName) => {
        const filePath = `/banners/home/desktop/${fileName.replace(
          './',
          ''
        )}`;
        let [title, _, order, expire] = fileName
          .replace('./', '')
          .replace('.png', '')
          .split('_');

        // let expireDate = new Date(new Date().getFullYear(), parseInt(expire.split('-')[1]) - 1, parseInt(expire.split('-')[0]));
        // let today = new Date();
        // if (expireDate < today) {
        //   return null;
        // }

        return {
          title,
          expire,
          image: filePath,
          imageMobile: filePath.replace('desktop', 'mobile'),
          link: '/',
          order: parseInt(order),

        };
      }).filter((banner) => banner !== null);
    };


    const page = ref<CmsPage | null>(null);


    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(async () => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      await loadCarouselBanners();
      loadHomeBanners();

      setTimeout(() => {
        loaded.value = true;
      }, 100);
    });

    const pageTitle = computed(() => page.value?.title || '');

    return {
      page,
      banners,
      settings,
      loaded,
      pageTitle,
      homeBanners,
    };
  },
  head() {
    const pageTitle = 'Espacial Suprimentos';
    return {
      title: pageTitle.toString(),
    };
  },
});
